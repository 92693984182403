
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('../bootstrap');
require('../plugins');
window.moment = require('moment');

try {
    require('slick-lightbox');

} catch (e) {}
/**
 * Next, we will create a fresh React application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

require('./components/GeosuggestProposal');
require('./components/LineItemsApp');
require('./components/CommercialLineItemsApp');
$('select').select2({
	height:'45px',
});
