import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import Geosuggest from 'react-geosuggest'

export default class GeosuggestProposal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            address_unit: "",
            address_line_one: "",
            address_suburb: "",
            address_city: "",
            company_name: "",
            address_postcode: "",
            is_hidden_address: true,
            is_hidden_customer_address: true,
            is_company: false,
            name: "",
            start_date: "",
            start_time: "",
        }
        this.onSuggestSelect = this.onSuggestSelect.bind(this)
        this.onSuggestNoResults = this.onSuggestNoResults.bind(this)
    }

    /**
     * When a suggest got selected
     * @param  {Object} suggest The suggest
     */
    onSuggestSelect(suggest) {
        if (suggest !== undefined) {
            this.setState({
                address_line_one: suggest.gmaps.address_components.find(item => item.types.includes('street_number')).long_name + ' ' + suggest.gmaps.address_components.find(item => item.types.includes('route')).long_name,
                address_suburb: suggest.gmaps.address_components.find(item => item.types.includes('locality')).long_name,
                address_city: suggest.gmaps.address_components.find(item => item.types.includes('administrative_area_level_2')).long_name,
                address_postcode: suggest.gmaps.address_components.find(item => item.types.includes('postal_code')).long_name,
            })
        }
    }

    /**
     * When there are no suggest results
     * @param {String} userInput The user input
     */
    onSuggestNoResults(userInput) {
        this.setState({
            display_form: true,
        })
    }

    toggleHidden() {
        this.setState({
            is_hidden_address: !this.state.is_hidden_address
        })
    }

    toggleHiddenCustomerAddress() {
        this.setState({
            is_hidden_customer_address: !this.state.is_hidden_customer_address
        })
    }

    toggleIsCompany() {
        this.setState({
            is_company: !this.state.is_company
        })
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-6 col-sm-4 col-md-2 mb-2">
                        <input type="text" className="form-control form-control-borders roch-input" required
                            id="address_unit_not_hidden"
                            name="address_unit"
                            placeholder="Unit"
                        />
                    </div>

                    <div className="col-12 col-sm-8 col-md-10">
                        <Geosuggest
                            id="address"
                            placeholder="Address"
                            onSuggestSelect={this.onSuggestSelect}
                            onSuggestNoResults={this.onSuggestNoResults}
                            location={new google.maps.LatLng(-27.4738315, 153.0261939)}
                            radius="20"
                            autoComplete='no'
                            inputClassName='form-control form-control-borders roch-input'
                        />
                    </div>
                </div>

                <input type="text" className="form-control form-control-borders roch-input" required
                       id="address_line_one_hidden"
                       value={this.state.address_line_one}
                       hidden={true}
                />

                <input type="text" className="form-control form-control-borders roch-input" required
                       id="address_suburb_hidden"
                       value={this.state.address_suburb}
                       hidden={true}
                />

                <input type="text" className="form-control form-control-borders roch-input" required
                       id="address_city_hidden"
                       value={this.state.address_city}
                       hidden={true}
                />

                <input type="text" className="form-control form-control-borders roch-input" required
                       id="address_postcode_hidden"
                       value={this.state.address_postcode}
                       hidden={true}
                />
                <a href="#" id="cant-find-address" onClick={this.toggleHidden.bind(this)}>Can't find your address? Click here</a>
                {!this.state.is_hidden_address && <Address parentState={this.state}/>}
                <br>
                </br>
                <br>
                </br>
                <a href="#" id="same-address" onClick={this.toggleHiddenCustomerAddress.bind(this)}><i>Click here if billing address is different to site address</i></a>
                {!this.state.is_hidden_customer_address && <CustomerAddress parentState={this.state}/>}
                <br>
                </br>
                <br>
                </br>
                
                    <input type="text" className="form-control form-control-borders roch-input"
                           id="company_name"
                           placeholder="Company Name"
                           onChange={(event) => {
                               this.setState({
                                   company_name: event.target.value
                               })
                           }}
                           value={this.state.company_name}
                    />
            </div>
        )
    }
}

class CustomerAddress extends Component {
    constructor(props) {
        super(props)
        this.state = {
            address_line_one: "",
            address_suburb: "",
            address_city: "",
            address_postcode: "",
            is_hidden_address: true,
            name: "",
            start_date: "",
            start_time: "",
        }
        this.onSuggestSelect = this.onSuggestSelect.bind(this)
        this.onSuggestNoResults = this.onSuggestNoResults.bind(this)
    }

    /**
     * When a suggest got selected
     * @param  {Object} suggest The suggest
     */
    onSuggestSelect(suggest) {
        this.setState({
            address_line_one: suggest.gmaps.address_components.find(item => item.types.includes('street_number')).long_name + ' ' + suggest.gmaps.address_components.find(item => item.types.includes('route')).long_name,
            address_suburb: suggest.gmaps.address_components.find(item => item.types.includes('locality')).long_name,
            address_city: suggest.gmaps.address_components.find(item => item.types.includes('administrative_area_level_2')).long_name,
            address_postcode: suggest.gmaps.address_components.find(item => item.types.includes('postal_code')).long_name,
        })
    }

    /**
     * When there are no suggest results
     * @param {String} userInput The user input
     */
    onSuggestNoResults(userInput) {
        this.setState({
            display_form: true,
        })
    }

    toggleHidden() {
        this.setState({
            is_hidden_address: !this.state.is_hidden_address
        })
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-6 col-sm-4 col-md-2 mb-2">
                        <input type="text" className="form-control form-control-borders roch-input" required
                            id="customer_address_unit_not_hidden"
                            name="customer_address_unit_not_hidden"
                            placeholder="Unit"
                        />
                    </div>

                    <div className="col-12 col-sm-8 col-md-10">
                        <Geosuggest
                            id="address"
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            onChange={this.onChange}
                            placeholder="Address"
                            onSuggestSelect={this.onSuggestSelect}
                            onSuggestNoResults={this.onSuggestNoResults}
                            location={new google.maps.LatLng(53.558572, 9.9278215)}
                            radius="20"
                            inputClassName='form-control form-control-borders roch-input'
                        />
                    </div>
                </div>
                <input type="text" className="form-control form-control-borders roch-input" required
                       id="customer_address_line_one_hidden"
                       value={this.state.address_line_one}
                       hidden={true}
                />

                <input type="text" className="form-control form-control-borders roch-input" required
                       id="customer_address_suburb_hidden"
                       value={this.state.address_suburb}
                       hidden={true}
                />

                <input type="text" className="form-control form-control-borders roch-input" required
                       id="customer_address_city_hidden"
                       value={this.state.address_city}
                       hidden={true}
                />

                <input type="text" className="form-control form-control-borders roch-input" required
                       id="customer_address_postcode_hidden"
                       value={this.state.address_postcode}
                       hidden={true}
                />
                <a href="#" id="cant-find-address" onClick={this.toggleHidden.bind(this)}><i>Can't find your address? Click here</i></a>
                {!this.state.is_hidden_address && <CustomerAddressExtra parentState={this.state}/>}
            </div>
        )
    }
}

class CustomerAddressExtra extends Component {
    constructor(props) {
        super()
        this.state = {
            address_line_one: "",
            address_suburb: "",
            address_city: "",
            address_postcode: "",
        }
    }

    componentDidMount() {
        this.setState({
            address_line_one: this.props.parentState.address_line_one,
            address_suburb: this.props.parentState.address_suburb,
            address_city: this.props.parentState.address_city,
            address_postcode: this.props.parentState.address_postcode,
        })
    }

    componentWillReceiveProps(props) {
        this.setState({
            address_line_one: props.parentState.address_line_one,
            address_suburb: props.parentState.address_suburb,
            address_city: props.parentState.address_city,
            address_postcode: props.parentState.address_postcode,
        })
    }

    handleChange(name, e) {
        let newState = {}
        newState[name] = e.target.value
        this.setState(newState)
    }

    render() {
        return (
            <div className="row mb-3">
                <div className="col-md-6 mt-2">
                    <label className="form-control-label d-block p-bold">Unit</label>
                    <input type="text" className="form-control form-control-borders roch-input"
                           id="customer_address_unit"
                    />
                </div>
                <div className="col-md-6 mt-2">
                    <label className="form-control-label d-block p-bold">Address line one</label>
                    <input type="text" className="form-control form-control-borders roch-input" required
                           id="customer_address_line_one"
                           value={this.state.address_line_one}
                           onChange={this.handleChange.bind(this, 'address_line_one')}
                    />
                </div>
                <div className="col-md-6 mt-2">
                    <label className="form-control-label d-block p-bold">Suburb</label>
                    <input type="text" className="form-control form-control-borders roch-input" required
                           id="customer_address_suburb"
                           value={this.state.address_suburb}
                           onChange={this.handleChange.bind(this, 'address_suburb')}
                    />
                </div>
                <div className="col-md-6 mt-2">
                    <label className="form-control-label d-block p-bold">City</label>
                    <input type="text" className="form-control form-control-borders roch-input" required
                           id="customer_address_city"
                           value={this.state.address_city}
                           onChange={this.handleChange.bind(this, 'address_city')}
                    />
                </div>
                <div className="col-md-6 mt-2">
                    <label className="form-control-label d-block p-bold">Postcode</label>
                    <input type="text" className="form-control form-control-borders roch-input" required
                           id="customer_address_postcode"
                           value={this.state.address_postcode}
                           onChange={this.handleChange.bind(this, 'address_postcode')}
                    />
                </div>
            </div>
        )
    }
}

class Address extends Component {
    constructor(props) {
        super()
        this.state = {
            address_unit: "",
            address_line_one: "",
            address_suburb: "",
            address_city: "",
            address_postcode: "",
        }
    }

    componentDidMount() {
        this.setState({
            address_unit: this.props.parentState.address_unit,
            address_line_one: this.props.parentState.address_line_one,
            address_suburb: this.props.parentState.address_suburb,
            address_city: this.props.parentState.address_city,
            address_postcode: this.props.parentState.address_postcode,
        })
    }

    componentWillReceiveProps(props) {
        this.setState({
            address_unit: props.parentState.address_unit,
            address_line_one: props.parentState.address_line_one,
            address_suburb: props.parentState.address_suburb,
            address_city: props.parentState.address_city,
            address_postcode: props.parentState.address_postcode,
        })
    }

    handleChange(name, e) {
        let newState = {}
        newState[name] = e.target.value
        this.setState(newState)
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-6 mt-3">
                    <label className="form-control-label d-block p-bold">Unit</label>
                    <input type="text" className="form-control form-control-borders roch-input" required
                           id="address_unit"
                           value={this.state.address_unit}
                           onChange={this.handleChange.bind(this, 'address_unit')}
                    />
                </div>
                <div className="col-md-6 mt-3">
                    <label className="form-control-label d-block p-bold">Address</label>
                    <input type="text" className="form-control form-control-borders roch-input" required
                           id="address_line_one"
                           value={this.state.address_line_one}
                           onChange={this.handleChange.bind(this, 'address_line_one')}
                    />
                </div>
                <div className="col-md-6 mt-3">
                    <label className="form-control-label d-block p-bold">Suburb</label>
                    <input type="text" className="form-control form-control-borders roch-input" required
                           id="address_suburb"
                           value={this.state.address_suburb}
                           onChange={this.handleChange.bind(this, 'address_suburb')}
                    />
                </div>
                <div className="col-md-6 mt-3">
                    <label className="form-control-label d-block p-bold">City</label>
                    <input type="text" className="form-control form-control-borders roch-input" required
                           id="address_city"
                           value={this.state.address_city}
                           onChange={this.handleChange.bind(this, 'address_city')}
                    />
                </div>
                <div className="col-md-6 mt-3">
                    <label className="form-control-label d-block p-bold">Postcode</label>
                    <input type="text" className="form-control form-control-borders roch-input" required
                           id="address_postcode"
                           value={this.state.address_postcode}
                           onChange={this.handleChange.bind(this, 'address_postcode')}
                    />
                </div>
            </div>
        )
    }
}

if (document.getElementById('geosuggest')) {
    ReactDOM.render(<GeosuggestProposal/>, document.getElementById('geosuggest'));
}
