import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import {ClimbingBoxLoader} from 'react-spinners'
import styles from './loading.css'

// Previous money formatter
// const numberWithCommas = (x) => {
//     return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// }

/**
 * New money formatter, Code stolen from:
 * https://stackoverflow.com/questions/149055/how-can-i-format-numbers-as-dollars-currency-string-in-javascript
 */
function formatMoney(n, c, d, t) {
    var c = isNaN(c = Math.abs(c)) ? 2 : c,
    d = d == undefined ? "." : d,
    t = t == undefined ? "," : t,
    s = n < 0 ? "-" : "",
    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
    j = (j = i.length) > 3 ? j % 3 : 0;
  
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};

export default class LineItemsApp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            scopeCollections: [],
            isLoading: false,
            quoteVersion: null,
            quote: null,
            quoteVersionID: '',
            domain: '',
        }
        this.changeSelectedForScopeCollection = this.changeSelectedForScopeCollection.bind(this)
        this.refreshList = this.refreshList.bind(this)
    }

    componentWillMount() {
        let tempArr = []
        JSON.parse(this.props.quote_version).scope_collections.forEach(item => {
            tempArr.push(item)
        })
        this.setState(preState => {
            return {
                scopeCollections: tempArr,
                quoteVersion: JSON.parse(this.props.quote_version),
                quote: JSON.parse(this.props.quote),
                quoteVersionID: window.location.href.split('/')[window.location.href.split('/').length - 1],
                domain: window.location.hostname,
            }
        })
    }

    componentDidMount() {
        this.refreshList()
    }

    changeSelectedForScopeCollection(scopeCollectionID) {
        this.setState(preState => {
            return {
                isLoading: true
            }
        })
        axios.post(`/api/scope_collection/${this.state.quoteVersion.hash_id}`, {scopeCollectionID: scopeCollectionID})
            .then(res => {
                this.refreshList()
            })
    }

    refreshList() {
        this.setState(preState => {
            return {
                isLoading: true
            }
        })

        axios.get(`/api/proposal/${this.state.quoteVersionID}`)
            .then(res => {
                let tempArr = []
                
                res.data.scope_collections.forEach(scopeCollection => tempArr.push(scopeCollection))
                this.setState(preState => {
                    return {
                        scopeCollections: tempArr,
                        quoteVersion: {...res.data},
                        isLoading: false,
                    }
                })
            })
    }

    render() {
        const qbccView = this.state.quoteVersion.qbcc_rate_id ? (
            <div className="mb-2 d-flex flex-column">
                <div className="d-flex align-items-baseline mb-1">
                    <h6 className="ml-auto mb-0 p-bold mr-2">QBCC:</h6>
                    <h4 className="ml-auto mb-0">
                        ${formatMoney(this.state.quoteVersion.showingQBCCPrice)}
                    </h4>
                </div>
                <small className="ml-auto open-sans qbcc_tooltip"><i><a href="#">What's this?</a></i></small>
            </div>
        ) : null
        const totalPriceView = this.state.quoteVersion.price > 3000 ?
            (
                <div className="ml-auto mt-2">
                    <h6 className="d-inline-block p-bold mr-2">TOTAL (inc GST):</h6>
                    <h4 className="d-inline-block">
                        $<span id="final-quote-price">{formatMoney(this.state.quoteVersion.showingTotalPrice)}</span>
                    </h4>
                </div>
            ) : (
                <div>
                    <div className="ml-auto">
                        <h6 className="d-inline-block p-bold mr-2"><b>TOTAL (inc GST):</b></h6>
                        <h4 className="d-inline-block">
                            $<span id="final-quote-price">{formatMoney(this.state.quoteVersion.showingTotalPrice)}</span>
                        </h4>
                    </div>
                </div>
            )

        const optionalScopeCollectionsView = this.state.scopeCollections.filter(scopeCollection => scopeCollection.optional && scopeCollection.selected === 0).length !== 0 ?
            (
                <div className="bg-white row d-flex justify-content-center">
                    <div className="col-md-12 p-5">
                        <h3>Optional Items</h3>
                        <h4 className="mt-3 open-sans">As per your request the following items have been set aside as
                            optional.</h4>
                        <p className="mt-3 open-sans">You may choose which items you wish to proceed with and
                            your cost will be updated accordingly.</p>
                    </div>
                    <div className="w-100"></div>
                    <div className="bg-white m-5 col-md-12">
                        <OptionalScopeCollectionsList
                            scopeCollections={this.state.scopeCollections.filter(scopeCollection =>
                                scopeCollection.optional && scopeCollection.selected === 0)}
                            changeSelectedForScopeCollection={this.changeSelectedForScopeCollection}
                            refreshList={this.refreshList}/>
                    </div>
                </div>
            ) : null

        const loadingView = this.state.isLoading ? (
            <div id="overlay">
                <div id="text">
                    <ClimbingBoxLoader
                        color={'#4690b4'}
                        loading={this.state.isLoading}/>
                </div>
            </div>
        ) : null

        return (
            <div className="" id="authorise-work">
                <div className="row d-flex justify-content-center">
                    {loadingView}
                    <div className="w-100"></div>

                    <div className="bg-white p-3 m-5 col-12">
                        <ScopeCollectionsList scopeCollections={this.state.scopeCollections.filter(scopeCollection =>
                            !scopeCollection.optional || scopeCollection.selected === 1)}
                                              changeSelectedForScopeCollection={this.changeSelectedForScopeCollection}
                                              refreshList={this.refreshList}/>

                        <div className="col-12 mt-3 d-flex ml-auto align-items-end flex-column">
                            <div className="mb-2 d-flex align-items-baseline">
                                <h6 className="ml-auto m-0 mr-2 p-bold">GST:</h6>
                                <h4 className="ml-auto m-0">
                                    ${formatMoney(this.state.quoteVersion.gst)}
                                </h4>
                            </div>
                            {qbccView}
                            {totalPriceView}
                        </div>
                    </div>
                </div>
                {optionalScopeCollectionsView}
            </div>
        )
    }
}

class ScopeCollectionsList extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        let divTop = {
            borderTop: '0 solid',
        };
        return (
            <div className="table-responsive">
                <table className="table" id="quote-table">
                    <thead>
                    <tr>
                        <th style={divTop} scope="p-bold"></th>
                        <th style={divTop} scope="p-bold">ITEM:</th>
                        <th style={divTop} scope="p-bold">PRICE:</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.scopeCollections
                        .map(scopeCollection =>
                            <ScopeCollectionItem scopeCollection={scopeCollection} key={scopeCollection.id}
                                                 changeSelectedForScopeCollection={this.props.changeSelectedForScopeCollection}
                                                 refreshList={this.props.refreshList}/>)
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}

class ScopeCollectionItem extends Component {
    constructor(props) {
        super(props)
    }

    handleCheckClick() {
        this.props.changeSelectedForScopeCollection(this.props.scopeCollection.id)
    }

    render() {
        return (
            <tr className="border-bottom">
                <td className="py-5">
                    <div className="repayment-plan-checkbox">
                        <input 
                            type="checkbox" 
                            onClick={() => this.handleCheckClick()}
                            defaultChecked={this.props.scopeCollection.selected == 1}
                            className="checkbox" 
                            id={`scopeCollectionCheckbox${this.props.scopeCollection.id}`} 
                        />
                        <label htmlFor={`scopeCollectionCheckbox${this.props.scopeCollection.id}`}>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                </td>
                <td className="py-5">
                    <h4><b>{this.props.scopeCollection.name}</b></h4>
                </td>
                <td className="py-5 pr-3">
                    <h4 className="text-right">
                        <b>${formatMoney(this.props.scopeCollection.line_items.reduce((acc, curr) => acc + Number(curr.price), 0))}</b>
                    </h4>
                </td>
            </tr>
        )
    }
}

class OptionalScopeCollectionsList extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="table-responsive">
                <table className="table" id="quote-table">
                    <thead>
                    <tr>
                        <th scope="col p-bold">SELECT:</th>
                        <th scope="col p-bold">OPTIONAL ITEM:</th>
                        <th scope="col p-bold">PRICE (excl. GST):</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.scopeCollections
                        .map(scopeCollection =>
                            <OptionalScopeCollection scopeCollection={scopeCollection} key={scopeCollection.id}
                                                     changeSelectedForScopeCollection={this.props.changeSelectedForScopeCollection}
                                                     refreshList={this.props.refreshList}/>)
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}

class OptionalScopeCollection extends Component {
    constructor(props) {
        super(props)
    }

    handleCheckClick() {
        this.props.changeSelectedForScopeCollection(this.props.scopeCollection.id)
    }

    render() {
        const checkBoxView = this.props.scopeCollection.optional ?
            (
            <div>
                <input 
                    type="checkbox" 
                    onClick={() => this.handleCheckClick()}
                    defaultChecked={this.props.scopeCollection.selected == 1}
                    className="checkbox" 
                    id={`scopeCollectionCheckbox${this.props.scopeCollection.id}`} 
                />
                    <label htmlFor={`scopeCollectionCheckbox${this.props.scopeCollection.id}`}>
                        <span className="checkmark"></span>
                    </label>
            </div>
            ) : null
        return (
            <tr className="border-bottom">
                <td className="py-5">
                    <div className="repayment-plan-checkbox">
                        {checkBoxView}
                    </div>
                </td>
                <td className="py-5">
                    <h4><b>{this.props.scopeCollection.name}</b></h4>
                </td>
                <td className="py-5">
                    <h4>
                        <b>${formatMoney(this.props.scopeCollection.line_items.reduce((acc, curr) => acc + Number(curr.price), 0))}</b>
                    </h4>
                </td>
            </tr>
        )
    }
}

if (document.getElementById('line-items-app')) {
    const element = document.getElementById('line-items-app')
    const props = {...element.dataset}
    ReactDOM.render(<LineItemsApp {...props}/>, document.getElementById('line-items-app'));
}