/**
 * This bootstrap file is used for both frontend and backend
 */

window._ = require("lodash");
window.swal = require("sweetalert2");
window.Popper = require("popper.js").default;

/**
 * Font Awesome 5
 */
import fontawesome from "@fortawesome/fontawesome";
// import solid from '@fortawesome/fontawesome-free-solid';
// import regular from '@fortawesome/fontawesome-free-regular';
// import brands from '@fortawesome/fontawesome-free-brands';

// fontawesome.library.add(solid);
// fontawesome.library.add(regular);
// fontawesome.library.add(brands);

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
  window.$ = window.jQuery = require("jquery");

  // Required for BS4
  window.Tether = require("tether");

  window.datepicker = require("bootstrap-datepicker");

  window.timepicker = require("timepicker");

  require("select2/dist/js/select2.full.js");

  window.slick = require("slick-carousel");

  window.Popper = Popper;

  require("bootstrap");

  require("jquery-ui/ui/widgets/autocomplete.js");

  // WYSIWYG editor
  window.trumbowyg = require("trumbowyg");
  window.$.trumbowyg.svgPath = "/vendor/icons.svg";
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require("axios");

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

//Our global variable for getting the S3 bucket path associated with the project
window.s3Path = process.env.MIX_AWS_PATH;

if (token) {
  window.axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
} else {
  console.error(
    "CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token"
  );
}

$.ajaxSetup({
  headers: {
    "X-CSRF-TOKEN": token.content,
  },
});

/**
 *
 * Set up FA to read css :after elements
 *
 */
window.FontAwesomeConfig = {
  searchPseudoElements: true,
};
/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */
import Echo from "laravel-echo";

window.Pusher = require("pusher-js");
window.Echo = new Echo({
  broadcaster: "pusher",
  // host: window.location.hostname + ':6001',
  key: process.env.MIX_PUSHER_APP_KEY,
  cluster: process.env.MIX_PUSHER_APP_CLUSTER,
  forceTLS: true,
});

if (window.User) {
  window.Echo.private("users." + window.User.id).notification(
    (notification) => {
      new Notification(notification.title, {
        title: notification.title,
        body: notification.body,
        image: notification.image || null,
        icon: notification.icon || null,
      });
    }
  );
}
